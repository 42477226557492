// Import the redux-saga/effects
import {call, put, takeEvery} from 'redux-saga/effects'

// Import all action and api's
import {DELETE_FILTER_REQUEST, DELETE_SEARCH, GET_SAVED_FILTER_BY_ID_REQUEST, GET_SAVED_FILTERS_REQUEST, GET_SEARCH, SAVE_FILTER_REQUEST, SAVE_SEARCH, SEARCH_ACTION, SEARCH_MATCH, UPDATE_FILTER_REQUEST} from '../action-types'

// Import all api's
import {deleteSearchRequest, getSearchRequest,getSavedFilter, saveSearchRequest, searchActionRequest, searchMatchRequest, getSavedFilterById, deleteFilter, saveFilter, updateFilter} from '../api/Search'

import {deleteSearchSuccess, getSearchSuccess, saveSearchSuccess, searchActionSuccess, toggleShowCVWarning, talentALreadyApplied, searchMatchSuccess, getSavedFiltersSuccess, getSavedFiltersFailure, getSavedFilterByIdSuccess, getSavedFilterByIdFailure, deleteFilterFailure, getSavedFiltersRequest, saveFilterSuccess, saveFilterFailure, updateFilterSuccess, updateFilterFailure, deleteFilterSuccess} from '../actions/Search'

import {fetchError, fetchExpired, fetchStart, fetchSuccess} from '../actions/Common'

import { toast } from "react-toastify";



function* UpdateFilters(action) {
    try {
      const { searchFilterId, updatedData, token, intl } = action.payload;
      const updatedFilter = yield call(updateFilter, searchFilterId, updatedData, token);
      yield put(updateFilterSuccess(updatedFilter));
      toast.success(intl.formatMessage({ id: "search.updated" }));
    } catch (error) {
      yield put(updateFilterFailure(error.message));
    }
  }
  

function* saveFilters(action) {
    try {
      const { filterData, token, intl } = action.payload;
      const response = yield call(saveFilter, filterData, token);
      
      if (response.status === 201) {
        yield put(saveFilterSuccess(response.data));
        toast.success(intl.formatMessage({ id: "search.saved" }));  
      } else {
        yield put(saveFilterFailure(response.data));
      }
    } catch (error) {
      yield put(saveFilterFailure(error.message));
    }
  }

function* deleteFilters(action) {
    try {
        // yield put(fetchStart())
      const { searchFilterId, token } = action.payload;
      const response = yield call(deleteFilter, searchFilterId, token);
      
      if (response.status === 200) {   
          const newresponse = yield call(getSavedFilter, action.payload.token);
          if(newresponse.status === 200) {
              yield put(getSavedFiltersSuccess(newresponse.data)); 
              yield put(deleteFilterSuccess(newresponse.data));
            // yield put(fetchSuccess())
        }
      } else {
        yield put(deleteFilterFailure(response.data));
      }
    } catch (error) {
      yield put(deleteFilterFailure(error.message));
    }
  }

function* getSavedFilterId(action) {
    try {
        const { searchFilterId, token } = action.payload;
        yield put(fetchStart())
      const response = yield call(getSavedFilterById, searchFilterId, token);
      
      if (response.status === 200) {
        yield put(getSavedFilterByIdSuccess(response.data));
        yield put(fetchSuccess())
      } else {
        yield put(getSavedFilterByIdFailure(response.data));
      }
    } catch (error) {
      yield put(getSavedFilterByIdFailure(error.message));
    }
  }


function* getSavedFilters(action) {
    try {
      yield put(fetchStart())
      const response = yield call(getSavedFilter, action.payload.token);
      
      if (response.status === 200) {
        yield put(getSavedFiltersSuccess(response.data));
        yield put(fetchSuccess())
      } else {
        yield put(getSavedFiltersFailure(response.data));
      }
    } catch (error) {
      yield put(getSavedFiltersFailure(error.message));
    }
  }

function* getSearch({token}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getSearchRequest, token)
        if (response.status === 200) {
            yield put(getSearchSuccess(response?.data))
            //yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* saveSearch({token, form}) {
    try {
        yield put(fetchStart())
        const response = yield call(saveSearchRequest, token, form)
        if (response.status === 200) {
            yield put(saveSearchSuccess(response?.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* deleteSearch({token, id}) {
    try {
        yield put(fetchStart())
        const response = yield call(deleteSearchRequest, token, id)
        if (response.status === 200) {
            yield put(deleteSearchSuccess(response?.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* searchAction({token, form}) {
    try {
        yield put(fetchStart())
        const response = yield call(searchActionRequest, token, form)
        if (response.status === 200) {
            yield put(searchActionSuccess(response?.data))
            yield put(fetchSuccess())
        }
        if (response.status === 403) {
            yield put(toggleShowCVWarning())
            //talent must upload CV
            yield put(fetchSuccess())
        }
        if (response.status === 409) {
            //talent already applied
            yield put(talentALreadyApplied())
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* searchMatch({token, id}) {
    try {
        yield put(fetchStart())
        const response = yield call(searchMatchRequest, token, id)
        if (response.status === 200) {
            yield put(searchMatchSuccess(response?.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

// Export the saga (todo-saga)
export default function* todoSaga() {
    yield takeEvery(GET_SEARCH, getSearch)
    yield takeEvery(SAVE_SEARCH, saveSearch)
    yield takeEvery(DELETE_SEARCH, deleteSearch)
    yield takeEvery(SEARCH_ACTION, searchAction)
    yield takeEvery(SEARCH_MATCH, searchMatch)
    yield takeEvery(GET_SAVED_FILTERS_REQUEST, getSavedFilters)
    yield takeEvery(GET_SAVED_FILTER_BY_ID_REQUEST, getSavedFilterId)
    yield takeEvery(DELETE_FILTER_REQUEST, deleteFilters)
    yield takeEvery(SAVE_FILTER_REQUEST, saveFilters)
    yield takeEvery(UPDATE_FILTER_REQUEST, UpdateFilters)
}