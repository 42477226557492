import {
    GET_AVAILABILITY_SUCCESS,
    GET_AVATAR_SUCCESS,
    GET_CERTIFICATION_SUCCESS,
    GET_CITIES_SUCCESS,
    GET_CONTRACT_LEVEL_SUCCESS,
    GET_CONTRACT_MODE_SUCCESS,
    GET_CONTRACT_TIME_SUCCESS,
    GET_CONTRACT_TYPE_SUCCESS,
    GET_COUNTRIES_SUCCESS, GET_CURRENCY_SUCCESS,
    GET_EDUCATION_LEVEL_SUCCESS,
    GET_EDUCATION_STATUS_SUCCESS,
    GET_EDUCATION_SUCCESS,
    GET_EXP_RANGE_SUCCESS,
    GET_GENDER_SUCCESS,
    GET_HOBBY_SUCCESS,
    GET_SECTOR_SUCCESS,
    GET_INDUSTRY_SUCCESS,
    GET_SUB_INDUSTRY_SUCCESS,
    GET_SUB_SUB_INDUSTRY_SUCCESS,
    GET_LANGUAGE_SUCCESS,
    GET_REF_JOB_TITLE_SUCCESS,
    GET_SEARCH_URGENCY_SUCCESS,
    GET_SKILL_SUCCESS, GET_TIMEZONE_SUCCESS,
    GET_UNIVERSITY_SUCCESS,
    GET_VALUE_SUCCESS,
    GET_WORK_LOCATION_SUCCESS,
    SET_LIST_STATE, GET_MODULES_SUCCESS, RESET_LIST_STATE,
    GET_WORK_CONTRACT_SUCCESS, GET_COUNTRY_COMPLIANCE_SUCCESS
} from '../action-types'

const INIT_STATE = {
    countries: JSON.parse(localStorage.getItem('breedj_countries')) || [],
    regions: JSON.parse(localStorage.getItem('breedj_regions')) || [],
    sub_regions: JSON.parse(localStorage.getItem('breedj_sub_regions')) || [],
    cities: [],
    language: JSON.parse(localStorage.getItem('breedj_language')) || [],
    skill: [],
    hobbies: [],
    sector: JSON.parse(localStorage.getItem('breedj_sector')) || [],
    industry: JSON.parse(localStorage.getItem('breedj_industry')) || [],
    sub_industry: JSON.parse(localStorage.getItem('breedj_sub_industry')) || [],
    sub_sub_industry: JSON.parse(localStorage.getItem('breedj_sub_sub_industry')) || [],
    search_urgency: JSON.parse(localStorage.getItem('breedj_search_urgency')) || [],
    university: [],
    job_title: [],
    work_location: JSON.parse(localStorage.getItem('breedj_work_location')) || [],
    contract_mode: JSON.parse(localStorage.getItem('breedj_contract_mode')) || [],
    contract_type: JSON.parse(localStorage.getItem('breedj_contract_type')) || [],
    contract_time: JSON.parse(localStorage.getItem('breedj_contract_time')) || [],
    contract_level: JSON.parse(localStorage.getItem('breedj_contract_level')) || [],
    availability: JSON.parse(localStorage.getItem('breedj_availability')) || [],
    avatar: JSON.parse(localStorage.getItem('breedj_avatar')) || [],
    genders: JSON.parse(localStorage.getItem('breedj_genders')) || [],
    education_status: JSON.parse(localStorage.getItem('breedj_education_status')) || [],
    education: [],
    education_level: JSON.parse(localStorage.getItem('breedj_education_level')) || [],
    certification: [],
    exp_range: JSON.parse(localStorage.getItem('breedj_exp_range')) || [],
    currency: JSON.parse(localStorage.getItem('breedj_currency')) || [],
    values: JSON.parse(localStorage.getItem('breedj_values')) || [],
    timezones: JSON.parse(localStorage.getItem('breedj_timezones')) || [],
    modules: JSON.parse(localStorage.getItem('breedj_modules')) || [],
    work_contracts: [],
    country_compliance: []
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESET_LIST_STATE: {
            localStorage.removeItem('breedj_countries')
            localStorage.removeItem('breedj_regions')
            localStorage.removeItem('breedj_sub_regions')
            localStorage.removeItem('breedj_language')
            localStorage.removeItem('breedj_sector')
            localStorage.removeItem('breedj_industry')
            localStorage.removeItem('breedj_sub_industry')
            localStorage.removeItem('breedj_sub_sub_industry')
            localStorage.removeItem('breedj_search_urgency')
            localStorage.removeItem('breedj_work_location')
            localStorage.removeItem('breedj_contract_mode')
            localStorage.removeItem('breedj_contract_type')
            localStorage.removeItem('breedj_contract_time')
            localStorage.removeItem('breedj_contract_level')
            localStorage.removeItem('breedj_availability')
            localStorage.removeItem('breedj_avatar')
            localStorage.removeItem('breedj_genders')
            localStorage.removeItem('breedj_education_status')
            localStorage.removeItem('breedj_education_level')
            localStorage.removeItem('breedj_exp_range')
            localStorage.removeItem('breedj_currency')
            localStorage.removeItem('breedj_values')
            localStorage.removeItem('breedj_timezones')

            return {
                ...state,
                    countries: [],
            }
        }


        case GET_COUNTRIES_SUCCESS: {
            let countries = action?.data?.ref_country?.data;
            let regions_temp = [];
            let sub_regions_temp = [];
            let sub_regions_struc = [];

            for (let i = 0; i < countries.length; i++) {
                if (!regions_temp.includes(countries[i].region)) {
                    regions_temp.push(countries[i].region)
                }
                if (!sub_regions_temp.includes(countries[i].sub_region)) {
                    sub_regions_temp.push(countries[i].sub_region)
                    sub_regions_struc.push({
                        region: countries[i].region,
                        sub_region: countries[i].sub_region
                    })
                }
            }

            let regionOrder = ["africa","europe","americas","oceania","asia"];

            countries.sort((a, b) => {
                if (a.region === b.region) {
                    return a.name.localeCompare(b.name);
                }

                return regionOrder.indexOf(a.region) - regionOrder.indexOf(b.region);
            })

            localStorage.setItem('breedj_countries', JSON.stringify(countries || []))
            localStorage.setItem('breedj_regions', JSON.stringify(regions_temp || []))
            localStorage.setItem('breedj_sub_regions', JSON.stringify(sub_regions_struc || []))

            return {
                ...state,
                countries: countries || [],
                regions: regions_temp || [],
                sub_regions: sub_regions_struc || []
            }
        }

        case GET_EDUCATION_STATUS_SUCCESS: {
            localStorage.setItem('breedj_education_status', JSON.stringify(action?.data?.ref_education_status?.data || []))
            return {...state, education_status: action?.data?.ref_education_status?.data || []}
        }

        case GET_EDUCATION_SUCCESS: {
            return {...state, education: action?.data?.ref_education?.data || []}
        }

        case GET_EDUCATION_LEVEL_SUCCESS: {
            localStorage.setItem('breedj_education_level', JSON.stringify(action?.data || []))
            return {...state, education_level: action?.data || []}
        }        

        case GET_CERTIFICATION_SUCCESS: {
            return {...state, certification: action?.data?.ref_certification?.data || []}
        }

        case GET_EXP_RANGE_SUCCESS: {
            localStorage.setItem('breedj_exp_range', JSON.stringify(action?.data?.ref_experience_range?.data || []))
            return {...state, exp_range: action?.data?.ref_experience_range?.data || []}
        }

        case GET_CITIES_SUCCESS: {
            return {...state, cities: action?.data?.ref_city?.data || []}
        }

        case GET_INDUSTRY_SUCCESS: {
            localStorage.setItem('breedj_industry', JSON.stringify(action?.data?.ref_industry?.data || []))
            return {...state, industry: action?.data?.ref_industry?.data || []}
        }

        case GET_SECTOR_SUCCESS: {
            localStorage.setItem('breedj_sector', JSON.stringify(action?.data?.ref_sector?.data || []))
            return {...state, sector: action?.data?.ref_sector?.data || []}
        }

        case GET_SUB_INDUSTRY_SUCCESS: {
            localStorage.setItem('breedj_sub_industry', JSON.stringify(action?.data?.ref_sub_industry?.data || []))
            return {...state, sub_industry: action?.data?.ref_sub_industry?.data || []}
        }

        case GET_SUB_SUB_INDUSTRY_SUCCESS: {
            localStorage.setItem('breedj_sub_sub_industry', JSON.stringify(action?.data?.ref_sub_sub_industry?.data || []))
            return {...state, sub_sub_industry: action?.data?.ref_sub_sub_industry?.data || []}
        }

        case GET_LANGUAGE_SUCCESS: {
            let languages = action?.data?.ref_language?.data || [];
            languages.sort((a, b) => {
                return a.position - b.position;
            })

            localStorage.setItem('breedj_language', JSON.stringify(languages || []))
            return {...state, language: languages}
        }

        case GET_WORK_LOCATION_SUCCESS: {
            localStorage.setItem('breedj_work_location', JSON.stringify(action?.data?.ref_work_location?.data || []))
            return {...state, work_location: action?.data?.ref_work_location?.data || []}
        }

        case GET_CONTRACT_MODE_SUCCESS: {
            localStorage.setItem('breedj_contract_mode', JSON.stringify(action?.data?.ref_contract_mode?.data || []))
            return {...state, contract_mode: action?.data?.ref_contract_mode?.data || []}
        }

        case GET_CONTRACT_TYPE_SUCCESS: {
            localStorage.setItem('breedj_contract_type', JSON.stringify(action?.data?.ref_contract_type?.data || []))
            return {...state, contract_type: action?.data?.ref_contract_type?.data || []}
        }

        case GET_CONTRACT_LEVEL_SUCCESS: {
            localStorage.setItem('breedj_contract_level', JSON.stringify(action?.data?.ref_contract_level?.data || []))
            return {...state, contract_level: action?.data?.ref_contract_level?.data || []}
        }

        case GET_CONTRACT_TIME_SUCCESS: {
            localStorage.setItem('breedj_contract_time', JSON.stringify(action?.data?.ref_contract_time?.data || []))
            return {...state, contract_time: action?.data?.ref_contract_time?.data || []}
        }

        case GET_AVAILABILITY_SUCCESS: {
            localStorage.setItem('breedj_availability', JSON.stringify(action?.data?.ref_availability?.data || []))
            return {...state, availability: action?.data?.ref_availability?.data || []}
        }

        case GET_SKILL_SUCCESS: {
            return {...state, skill: action?.data?.ref_skill?.data || []}
        }

        case GET_HOBBY_SUCCESS: {
            return {...state, hobbies: action?.data?.ref_hobby?.data || []}
        }

        case GET_GENDER_SUCCESS: {
            localStorage.setItem('breedj_genders', JSON.stringify(action?.data?.ref_gender?.data || []))
            return {...state, genders: action?.data?.ref_gender?.data || []}
        }

        case GET_SEARCH_URGENCY_SUCCESS: {
            localStorage.setItem('breedj_search_urgency', JSON.stringify(action?.data?.ref_search_urgency?.data || []))
            return {...state, search_urgency: action?.data?.ref_search_urgency?.data || []}
        }

        case GET_UNIVERSITY_SUCCESS: {
            return {...state, university: action?.data?.ref_university?.data || []}
        }

        case GET_REF_JOB_TITLE_SUCCESS: {
            return {...state, job_title: action?.data?.ref_job_title?.data || []}
        }

        case GET_AVATAR_SUCCESS: {
            localStorage.setItem('breedj_avatar', JSON.stringify(action?.data?.ref_avatar?.data || []))
            return {...state, avatar: action?.data?.ref_avatar?.data || []}
        }

        case GET_CURRENCY_SUCCESS: {
            localStorage.setItem('breedj_currency', JSON.stringify(action?.data?.ref_currency?.data || []))
            return {...state, currency: action?.data?.ref_currency?.data || []}
        }

        case GET_VALUE_SUCCESS: {
            localStorage.setItem('breedj_values', JSON.stringify(action?.data?.ref_value?.data || []))
            return {...state, values: action?.data?.ref_value?.data || []}
        }

        case GET_TIMEZONE_SUCCESS: {
            localStorage.setItem('breedj_timezones', JSON.stringify(action?.data?.ref_timezone?.data || []))
            return {...state, timezones: action?.data?.ref_timezone?.data || []}
        }

        case GET_MODULES_SUCCESS: {
            localStorage.setItem('breedj_modules', JSON.stringify(action?.data?.ref_modules || []))
            return {...state, modules: action?.data?.ref_modules || []}
        }

        case GET_WORK_CONTRACT_SUCCESS: {
            return {...state, work_contracts: action?.payload || []}
        }

        case GET_COUNTRY_COMPLIANCE_SUCCESS: {
            return {...state, country_compliance: action?.payload || []}
        }

        case SET_LIST_STATE: {
            return {

                ...state,
                ...action?.payload
            }
        }

        default:
            return state
    }
}
