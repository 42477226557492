import logoTalenteum from '../assets/images/logo.png';
import logoBreedj from '../assets/images/new-logo-breedj.png';
import BreedjCguvEn from '../assets/documents/Breedj_cguv_en_2024.pdf';
import BreedjCguvFr from '../assets/documents/Breedj_cguv_fr_2024.pdf';
import TalenteumCguvEn from '../assets/documents/Talenteum_cguv_en_2024.pdf';
import TalenteumCguvFr from '../assets/documents/Talenteum_cguv_fr_2024.pdf';
import BreedjProtectDataEn from '../assets/documents/Breedj_data_protection_en.pdf';
import BreedjProtectDataFr from '../assets/documents/Breedj_data_protection_fr.pdf';
import TalenteumProtectDataFr from '../assets/documents/Talenteum_data_protection_fr.pdf';
import TalenteumProtectDataEn from '../assets/documents/Talenteum_data_protection_en.pdf';

const ogImage = "/talenteum3.jpg"

const CONFIG = {
    Talenteum: {
      name: "Talenteum",
      logoPath: logoTalenteum,
      lowercaseName: "talenteum",
      iconNav: "favicon",
      width: "w-96",
      disabledRoutes: [
        // "talent/search/job_offers",
        // "talent/candidature",
        // "talent/interviews",
        // "talent/search/job_offers/favorites",
        // "talent/search/job_offers/compare",
        // "company/job_offer/list",
        // "company/job_offer/view/:id",
        // "company/job_offer/preview/:id",
        // "company/job_offer/fiche/:id",
        // "company/job_offer/guide/:id",
        // "company/job_offer/match/:id",
        // "company/job_offer/auto",
        // "company/job_offer/auto_first",
        // "company/job_offer/flow/:id",
        // "company/job_offer/search/talents/favorites",
        // "company/job_offer/candidature",
        // "company/job_offer/candidature/single",
        // "company/search/talents",
        // "company/proposals",
        // "visio/:id"
      ],
      disabledPages: [
        // "job_offer",
        // "recruitment"
      ],
      code: "talenteum",
      bgSecondary: "#0284c7",
      cguv_en: TalenteumCguvEn,
      cguv_fr: TalenteumCguvFr,
      protectData_en: TalenteumProtectDataEn,
      protectData_fr: TalenteumProtectDataFr,
      metaDescription: "Join our community for free now!",
      ogTitle: "Talenteum - Remote Work Platform",
      ogDescription: "Talenteum connects businesses with remote professionals in Africa.",
      twitterDescription: "Talenteum helps businesses hire skilled African talent remotely.",
      ogImage: ogImage
    },
    Breedj: {
      name: "Breedj",
      logoPath: logoBreedj,
      lowercaseName: "breedj",
      iconNav: "favicon-breedj",
      width: "w-64",
      disabledRoutes: [],
      disabledPages: [],
      code:"breedj",
      bgSecondary: "#002496",
      cguv_en: BreedjCguvEn,
      cguv_fr: BreedjCguvFr,
      protectData_en: BreedjProtectDataEn,
      protectData_fr: BreedjProtectDataFr,
      metaDescription: "Join the Breedj community now!",
      ogTitle: "Breedj - The Best Talent Platform",
      ogDescription: "Breedj helps businesses recruit remote professionals with ease.",
      twitterDescription: "Breedj enables businesses to find top remote talent easily.",
      ogImage: ogImage
    }
  };
  
  const currentPlatform = process.env.REACT_APP_CURRENT_PLATFORM || "Talenteum";
  
  const platformConfig = CONFIG[currentPlatform] || CONFIG.Talenteum;
  
  export default platformConfig;
  