import {
    GET_AVAILABILITY,
    GET_AVAILABILITY_SUCCESS,
    GET_AVATAR,
    GET_AVATAR_SUCCESS,
    GET_CERTIFICATION,
    GET_CERTIFICATION_SUCCESS,
    GET_CITIES,
    GET_CITIES_SUCCESS,
    GET_CONTRACT_LEVEL,
    GET_CONTRACT_LEVEL_SUCCESS,
    GET_CONTRACT_MODE,
    GET_CONTRACT_MODE_SUCCESS,
    GET_CONTRACT_TIME,
    GET_CONTRACT_TIME_SUCCESS,
    GET_CONTRACT_TYPE,
    GET_CONTRACT_TYPE_SUCCESS,
    GET_COUNTRIES,
    GET_COUNTRIES_SUCCESS, GET_CURRENCY, GET_CURRENCY_SUCCESS,
    GET_EDUCATION,
    GET_EDUCATION_LEVEL,
    GET_EDUCATION_LEVEL_SUCCESS,
    GET_EDUCATION_STATUS,
    GET_EDUCATION_STATUS_SUCCESS,
    GET_EDUCATION_SUCCESS,
    GET_EXP_RANGE,
    GET_EXP_RANGE_SUCCESS,
    GET_GENDER,
    GET_GENDER_SUCCESS,
    GET_HOBBY,
    GET_HOBBY_SUCCESS,
    GET_INDUSTRY,
    GET_INDUSTRY_SUCCESS,
    GET_SECTOR,
    GET_SECTOR_SUCCESS,
    GET_SUB_INDUSTRY,
    GET_SUB_INDUSTRY_SUCCESS,
    GET_SUB_SUB_INDUSTRY,
    GET_SUB_SUB_INDUSTRY_SUCCESS,
    GET_LANGUAGE,
    GET_LANGUAGE_SUCCESS,
    GET_REF_JOB_TITLE,
    GET_REF_JOB_TITLE_SUCCESS,
    GET_SEARCH_URGENCY,
    GET_SEARCH_URGENCY_SUCCESS,
    GET_SKILL,
    GET_SKILL_SUCCESS, GET_TIMEZONE, GET_TIMEZONE_SUCCESS,
    GET_UNIVERSITY,
    GET_UNIVERSITY_SUCCESS, GET_VALUE, GET_VALUE_SUCCESS,
    GET_WORK_LOCATION,
    GET_WORK_LOCATION_SUCCESS,
    SET_LIST_STATE, GET_MODULES, GET_MODULES_SUCCESS,
    RESET_LIST_STATE,
    GET_WORK_CONTRACT, GET_WORK_CONTRACT_SUCCESS,
    GET_COUNTRY_COMPLIANCE, GET_COUNTRY_COMPLIANCE_SUCCESS
} from '../action-types'

export const resetRefList = () => {
    return {
        type: RESET_LIST_STATE
    }
}

export const getCountries = (token, query, locale) => {
    return {
        type: GET_COUNTRIES,
        token: token,
        query: query,
        locale: locale
    }
}

export const getCountriesSuccess = (data) => {
    return {
        type: GET_COUNTRIES_SUCCESS,
        data: data?.data,
    }
}

export const getEducationStatus = (token, query, locale) => {
    return {
        type: GET_EDUCATION_STATUS,
        token: token,
        query: query,
        locale: locale
    }
}

export const getEducationStatusSuccess = (data) => {
    return {
        type: GET_EDUCATION_STATUS_SUCCESS,
        data: data?.data,
    }
}

export const getEducationLevel = (token, locale) => {
    return {
        type: GET_EDUCATION_LEVEL,
        token,
        locale
    }
}


export const getEducationLevelSuccess = (data) => {
    return {
        type: GET_EDUCATION_LEVEL_SUCCESS,
        data: data,
    }
}

export const getEducation = (token, query, locale) => {
    return {
        type: GET_EDUCATION,
        token: token,
        query: query,
        locale: locale
    }
}

export const getEducationSuccess = (data) => {
    return {
        type: GET_EDUCATION_SUCCESS,
        data: data?.data,
    }
}

export const getCertification = (token, query, locale) => {
    return {
        type: GET_CERTIFICATION,
        token: token,
        query: query,
        locale: locale
    }
}

export const getCertificationSuccess = (data) => {
    return {
        type: GET_CERTIFICATION_SUCCESS,
        data: data?.data,
    }
}

export const getCities = (token, query, locale) => {
    return {
        type: GET_CITIES,
        token: token,
        query: query,
        locale: locale
    }
}

export const getCitiesSuccess = (data) => {
    return {
        type: GET_CITIES_SUCCESS,
        data: data?.data,
    }
}
export const getAvatar = (token, query, locale) => {
    return {
        type: GET_AVATAR,
        token: token,
        query: query,
        locale: locale
    }
}

export const getAvatarSuccess = (data) => {
    return {
        type: GET_AVATAR_SUCCESS,
        data: data?.data,
    }
}

export const getGender = (token, query, locale) => {
    return {
        type: GET_GENDER,
        token: token,
        query: query,
        locale: locale
    }
}

export const getGenderSuccess = (data) => {
    return {
        type: GET_GENDER_SUCCESS,
        data: data?.data,
    }
}
export const getLanguage = (token, query, locale) => {
    return {
        type: GET_LANGUAGE,
        token: token,
        query: query,
        locale: locale
    }
}

export const getLanguageSuccess = (data) => {
    return {
        type: GET_LANGUAGE_SUCCESS,
        data: data?.data,
    }
}

export const getSkill = (token, query, locale) => {
    return {
        type: GET_SKILL,
        token: token,
        query: query,
        locale: locale
    }
}

export const getSkillSuccess = (data) => {
    return {
        type: GET_SKILL_SUCCESS,
        data: data?.data,
    }
}

export const getHobby = (token, query, locale) => {
    return {
        type: GET_HOBBY,
        token: token,
        query: query,
        locale: locale
    }
}

export const getHobbySuccess = (data) => {
    return {
        type: GET_HOBBY_SUCCESS,
        data: data?.data,
    }
}

export const getSearchUrgency = (token, query, locale) => {
    return {
        type: GET_SEARCH_URGENCY,
        token: token,
        query: query,
        locale: locale
    }
}

export const getSearchUrgencySuccess = (data) => {
    return {
        type: GET_SEARCH_URGENCY_SUCCESS,
        data: data?.data,
    }
}

export const getRefJobTitle = (token, query, locale) => {
    return {
        type: GET_REF_JOB_TITLE,
        token: token,
        query: query,
        locale: locale
    }
}

export const getRefJobTitleSuccess = (data) => {
    let results = [];
    for (let i = 0; i < data?.hits?.hits?.length; i++) {
        results.push(data?.hits?.hits[i]?._source)
    }

    return {
        type: GET_REF_JOB_TITLE_SUCCESS,
        data: {
            ref_job_title : { data : results }
        },
    }
}

export const getRefUniversity = (token, query, locale) => {
    return {
        type: GET_UNIVERSITY,
        token: token,
        query: query,
        locale: locale
    }
}

export const getRefUniversitySuccess = (data) => {
    return {
        type: GET_UNIVERSITY_SUCCESS,
        data: data?.data,
    }
}

export const getAvailability = (token, query, locale) => {
    return {
        type: GET_AVAILABILITY,
        token: token,
        query: query,
        locale: locale
    }
}

export const getAvailabilitySuccess = (data) => {
    return {
        type: GET_AVAILABILITY_SUCCESS,
        data: data?.data,
    }
}

export const getContractMode = (token, query, locale) => {
    return {
        type: GET_CONTRACT_MODE,
        token: token,
        query: query,
        locale: locale
    }
}

export const getContractModeSuccess = (data) => {
    return {
        type: GET_CONTRACT_MODE_SUCCESS,
        data: data?.data,
    }
}

export const getContractType = (token, query, locale) => {
    return {
        type: GET_CONTRACT_TYPE,
        token: token,
        query: query,
        locale: locale
    }
}

export const getContractTypeSuccess = (data) => {
    return {
        type: GET_CONTRACT_TYPE_SUCCESS,
        data: data?.data,
    }
}

export const getContractTime = (token, query, locale) => {
    return {
        type: GET_CONTRACT_TIME,
        token: token,
        query: query,
        locale: locale
    }
}

export const getContractTimeSuccess = (data) => {
    return {
        type: GET_CONTRACT_TIME_SUCCESS,
        data: data?.data,
    }
}

export const getContractLevel = (token, query, locale) => {
    return {
        type: GET_CONTRACT_LEVEL,
        token: token,
        query: query,
        locale: locale
    }
}

export const getContractLevelSuccess = (data) => {
    return {
        type: GET_CONTRACT_LEVEL_SUCCESS,
        data: data?.data,
    }
}

export const getWorkLocation = (token, query, locale) => {
    return {
        type: GET_WORK_LOCATION,
        token: token,
        query: query,
        locale: locale
    }
}

export const getWorkLocationSuccess = (data) => {
    return {
        type: GET_WORK_LOCATION_SUCCESS,
        data: data?.data,
    }
}

export const getIndustry = (token, query, locale) => {
    return {
        type: GET_INDUSTRY,
        token: token,
        query: query,
        locale: locale
    }
}

export const getIndustrySuccess = (data) => {
    return {
        type: GET_INDUSTRY_SUCCESS,
        data: data?.data,
    }
}

export const getSector = (token, query, locale) => {
    return {
        type: GET_SECTOR,
        token: token,
        query: query,
        locale: locale
    }
}

export const getSectorSuccess = (data) => {
    return {
        type: GET_SECTOR_SUCCESS,
        data: data?.data,
    }
}

export const getSubIndustry = (token, query, locale) => {
    return {
        type: GET_SUB_INDUSTRY,
        token: token,
        query: query,
        locale: locale
    }
}

export const getSubIndustrySuccess = (data) => {
    return {
        type: GET_SUB_INDUSTRY_SUCCESS,
        data: data?.data,
    }
}

export const getSubSubIndustry = (token, query, locale) => {
    return {
        type: GET_SUB_SUB_INDUSTRY,
        token: token,
        query: query,
        locale: locale
    }
}

export const getSubSubIndustrySuccess = (data) => {
    return {
        type: GET_SUB_SUB_INDUSTRY_SUCCESS,
        data: data?.data,
    }
}

export const getExpRange = (token, query, locale) => {
    return {
        type: GET_EXP_RANGE,
        token: token,
        query: query,
        locale: locale
    }
}

export const getExpRangeSuccess = (data) => {
    return {
        type: GET_EXP_RANGE_SUCCESS,
        data: data?.data,
    }
}

export const setContactsState = (state) => {
    return {
        type: SET_LIST_STATE,
        payload: state,
    }
}

export const getCurrency = (token, query, locale) => {
    return {
        type: GET_CURRENCY,
        token: token,
        query: query,
        locale: locale
    }
}

export const getCurrencySuccess = (data) => {
    return {
        type: GET_CURRENCY_SUCCESS,
        data: data?.data,
    }
}

export const getValue = (token, query, locale) => {
    return {
        type: GET_VALUE,
        token: token,
        query: query,
        locale: locale
    }
}

export const getValueSuccess = (data) => {
    return {
        type: GET_VALUE_SUCCESS,
        data: data?.data,
    }
}

export const getTimezone = (token, query, locale) => {
    return {
        type: GET_TIMEZONE,
        token: token,
        query: query,
        locale: locale
    }
}

export const getTimezoneSuccess = (data) => {
    return {
        type: GET_TIMEZONE_SUCCESS,
        data: data?.data,
    }
}

export const getModules = (token, query, locale) => {
    return {
        type: GET_MODULES,
        token: token,
        query: query,
        locale: locale
    }
}

export const getModulesSuccess = (data) => {
    return {
        type: GET_MODULES_SUCCESS,
        data: data?.data,
    }
}

export const getWorkContract = (token, locale) => {
    return {
        type: GET_WORK_CONTRACT,
        token: token
    }
}

export const getWorkContractSuccess = (data) => {
    return {
        type: GET_WORK_CONTRACT_SUCCESS,
        payload: data
    }
}

export const getCountryCompliance = (token) => {
    return {
        type: GET_COUNTRY_COMPLIANCE,
        token: token
    }
}


export const getCountryComplianceSuccess = (data) => {
    return {
        type: GET_COUNTRY_COMPLIANCE_SUCCESS,
        payload: data
    }
}
