import {
    DELETE_SEARCH_SUCCESS,
    GET_SEARCH_SUCCESS,
    SAVE_SEARCH_SUCCESS,
    SEARCH_ACTION_SUCCESS,
    SET_REF_STATE,
    OPEN_FILTER_JOB,
    CLOSE_FILTER_JOB,
    UPLOAD_CV_WARNING,
    TALENT_ALREADY_APPLIED,
    UPDATE_APPLICATION_FLOW_SUCCESS, UPDATE_APPLICATION_TIME_SUCCESS,
    SEARCH_MATCH_SUCCESS,
    GET_SAVED_FILTERS_REQUEST,
    GET_SAVED_FILTERS_SUCCESS,
    GET_SAVED_FILTERS_FAILURE,
    GET_SAVED_FILTER_BY_ID_REQUEST,
    GET_SAVED_FILTER_BY_ID_SUCCESS,
    GET_SAVED_FILTER_BY_ID_FAILURE,
    DELETE_FILTER_REQUEST,
    DELETE_FILTER_SUCCESS,
    DELETE_FILTER_FAILURE,
    SAVE_FILTER_FAILURE,
    SAVE_FILTER_SUCCESS,
    SAVE_FILTER_REQUEST,
    UPDATE_FILTER_REQUEST,
    UPDATE_FILTER_SUCCESS,
    UPDATE_FILTER_FAILURE
} from '../action-types'

const INIT_STATE = {
    searches: [],
    favorites: [],
    compares: [],
    sharings: [],
    applications: [],
    my_jobs: [],
    openFilterJob: false,
    cvWarning: false,
    alreadyApplied: false,
    matches : [],
    talent_matches : [],
    filters: [],
    savedFilter: null
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_FILTER_REQUEST:
            return { ...state, loading: true, error: null };
        case UPDATE_FILTER_SUCCESS:
            return {
                ...state,
                loading: false,
                filters: state.filters.map((filter) =>
                filter.id === action.payload.id ? action.payload : filter
                ),
            };
        case UPDATE_FILTER_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case SAVE_FILTER_REQUEST:
            return { ...state, loading: true, error: null }; 
        case SAVE_FILTER_SUCCESS:
            return {
                ...state,
                loading: false,
                filters: [...state.filters, action.payload],
            };
        case SAVE_FILTER_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case GET_SAVED_FILTERS_REQUEST:
            return { ...state, loading: true };
        case GET_SAVED_FILTERS_SUCCESS:
            return { ...state, loading: false, filters: action.payload };
        case GET_SAVED_FILTERS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case GET_SAVED_FILTER_BY_ID_REQUEST:
            return { ...state, loading: true, error: null };
        case GET_SAVED_FILTER_BY_ID_SUCCESS:
            return { ...state, loading: false, savedFilter: action.payload };
        case GET_SAVED_FILTER_BY_ID_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case DELETE_FILTER_REQUEST:
            return { ...state, loading: true, error: null };
        case DELETE_FILTER_SUCCESS:
            return { ...state, loading: false, filters: state.filters.filter(filter => filter.id !== action.payload), };
        case DELETE_FILTER_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case GET_SEARCH_SUCCESS: {
            return {
                ...state, searches: action?.data.searches,
            }
        }

        case UPLOAD_CV_WARNING: {
            return {
                ...state, cvWarning: !state.cvWarning,
            }
        }

        case TALENT_ALREADY_APPLIED: {
            return {
                ...state, alreadyApplied: !state.alreadyApplied,
            }
        }

        case SAVE_SEARCH_SUCCESS: {
            return {
                ...state, searches: action?.data.searches,
            }
        }

        case DELETE_SEARCH_SUCCESS: {
            return {
                ...state, searches: action?.data.searches,
            }
        }

        case OPEN_FILTER_JOB: {
            return {
                ...state, openFilterJob: true,
            }
        }

        case CLOSE_FILTER_JOB: {
            return {
                ...state, openFilterJob: false,
            }
        }

        case SEARCH_ACTION_SUCCESS: {
            return {
                ...state,
                favorites: action?.data.favorites,
                compares: action?.data.compares,
                sharings: action?.data.sharings,
                applications: action?.data.applications,
                my_jobs: action?.data.my_jobs,
            }
        }

        case SET_REF_STATE: {
            return {
                ...state,
                ...action?.payload
            }
        }

        case UPDATE_APPLICATION_FLOW_SUCCESS : {
            if (state.applications.length > 0) {
                let copyApplications = state.applications;
                for (let i = 0; i < state.applications.length; i++) {
                    if (state.applications[i].id == action.data.id) {
                        copyApplications[i] = action.data
                        break
                    }
                }

                return {
                    ...state,
                    applications: copyApplications,
                }
            }

            return state
        }

        case UPDATE_APPLICATION_TIME_SUCCESS : {
            if (state.applications.length > 0) {
                let copyApplications = state.applications;
                for (let i = 0; i < state.applications.length; i++) {
                    if (state.applications[i].id == action.data.id) {
                        copyApplications[i] = action.data
                        break
                    }
                }

                return {
                    ...state,
                    applications: copyApplications,
                }
            }

            return state
        }

        case SEARCH_MATCH_SUCCESS: {
            return {
                ...state,
                matches: action?.data.matches,
                talent_matches: action?.data.talent_matches
            }
        }

        default:
            return state
    }
}
