import React, {Suspense, useEffect} from 'react'
import {
    BrowserRouter,
    Navigate,
    Routes,
    Route,
} from 'react-router-dom'
import {Provider} from 'react-redux'
import store from './redux/store'
import './App.scss'
import SuspenseFallback from './containers/SuspenseFallback'
import {StyledEngineProvider} from '@mui/material/styles'
import Login from './views/Auth/Login'
import LoginLang from './views/Auth/LoginLang'
import Register from './views/Auth/Register'
import RegisterLang from './views/Auth/RegisterLang'
import Phone from "./views/Auth/Phone";
import RequireAuth from './containers/RequireAuth'
import AppIntlProvider from './containers/AppIntlProvider'
import PageContainer from './containers/PageContainer'
import Autologin from "./views/Auth/Autologin";
import InvitationRefused from "./views/Auth/InvitationRefused";
import RegisterOAuth from "./views/Auth/RegisterOAuth";
import RegisterLinkedin from "./views/Auth/RegisterLinkedin";
import Unsubscribe from './views/Unsubscribe'
import Rating from './views/Talent/Rating'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify'
import { NavigationProvider } from './containers/NavigationContext';
import HistoryTracker from './containers/HistoryTracker';
import platformConfig from './config/config'

function App() {

    useEffect(() => {
        // Set the document title dynamically
        document.title = `${platformConfig.name} App`;

        // Update Favicon
        const icon = document.querySelector("link[rel='icon']");
        if (icon) {
            icon.href = `/${platformConfig.iconNav}.ico`;
        }

        // Function to update meta tags dynamically
        const updateMetaTag = (name, content) => {
            let metaTag = document.querySelector(`meta[name='${name}']`);
            if (!metaTag) {
                metaTag = document.createElement("meta");
                metaTag.name = name;
                document.head.appendChild(metaTag);
            }
            metaTag.content = content;
        };

        // Function to update Open Graph (og) meta tags dynamically
        const updateOGMetaTag = (property, content) => {
            let metaTag = document.querySelector(`meta[property='${property}']`);
            if (!metaTag) {
                metaTag = document.createElement("meta");
                metaTag.setAttribute("property", property);
                document.head.appendChild(metaTag);
            }
            metaTag.setAttribute("content", content);
        };

        // Update metadata dynamically
        updateMetaTag("description", platformConfig.metaDescription);
        updateMetaTag("twitter:description", platformConfig.twitterDescription);
        updateOGMetaTag("og:title", platformConfig.ogTitle);
        updateOGMetaTag("og:description", platformConfig.ogDescription);
        updateOGMetaTag("og:image", platformConfig.ogImage);
        updateOGMetaTag("og:image:width", "1200");
        updateOGMetaTag("og:image:height", "630");
        updateOGMetaTag("og:image:type", "image/jpg");

    }, []);

    return (
        <Provider store={store}>

            <AppIntlProvider>
                <StyledEngineProvider injectFirst>
                    <div className="App">
                        <Suspense fallback={<SuspenseFallback/>}>
                            <PageContainer>
                                <NavigationProvider>
                                    <ToastContainer />
                                    <BrowserRouter>
                                        <HistoryTracker />
                                        <Routes>
                                            <Route path="/" element={<Navigate replace to="/login" />} />
                                            <Route path="/loginlang/:lang" element={<LoginLang />} />
                                            <Route path={'/autologin'} element={<Autologin />} />
                                            <Route path="/login" element={<Login />} />
                                            <Route path="/login/:first" element={<Login />} />
                                            <Route path="/registeroauth" element={<RegisterOAuth />} />
                                            <Route path="/oauth/linkedin" element={<RegisterLinkedin />} />
                                            <Route path="/registerlang/:type/:lang" element={<RegisterLang />} />
                                            <Route path="/register/:type" element={<Register />} />
                                            <Route path="/phone" element={<Phone />} />
                                            <Route path="/invitation/refused" element={<InvitationRefused />} />
                                            <Route path="unsubscribe" element={<Unsubscribe />} />
                                            <Route path="talent/rating" element={<Rating/>}/>
                                            <Route
                                                path="/*"
                                                element={
                                                    <RequireAuth />
                                                }
                                            />

                                        </Routes>
                                    </BrowserRouter>
                                </NavigationProvider>
                            </PageContainer>
                        </Suspense>
                    </div>
                </StyledEngineProvider>
            </AppIntlProvider>
        </Provider>
    );
}

export default App;
