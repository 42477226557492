import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import axios from 'axios';
import React, {useEffect, useState, useCallback} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { verifyRecaptchaGeneralRequest, verifyRecaptchaPhoneRequest, verifyRecaptchaRequest } from '../../redux/actions/Auth';
const Recaptcha = ({onValidToken, type = null}) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const {token,recaptchaDataPhone,recaptchaDataGeneral, loading} = useSelector(({auth}) => auth)    
    const dispatch = useDispatch();
    
    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }
      const tokenRecaptcha = await executeRecaptcha('register');     
      
      if (tokenRecaptcha) {
        if (type == 'phone' && token) {
            dispatch(verifyRecaptchaPhoneRequest(tokenRecaptcha, token)); 
        } else {
            dispatch(verifyRecaptchaGeneralRequest(tokenRecaptcha));
        }
    }
  }, [executeRecaptcha, type, dispatch, token]);
  console.log(recaptchaDataGeneral);
  
  useEffect(() => {
    if (type === 'phone' && recaptchaDataPhone) {
        onValidToken(recaptchaDataPhone, recaptchaDataPhone?.tokenRecaptcha);
    } else if (type !== 'phone' && recaptchaDataGeneral) {
        onValidToken(recaptchaDataGeneral, recaptchaDataGeneral?.tokenRecaptcha);
    }
}, [loading, recaptchaDataPhone?.tokenRecaptcha, recaptchaDataGeneral?.tokenRecaptcha, onValidToken, type, recaptchaDataPhone, recaptchaDataGeneral]);

  
    //You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
      handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);
  
    return '';
  };

  export default Recaptcha
