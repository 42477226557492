import {axiosInstance, axiosInstanceGraphQL} from '../../axios/axios'

export const getHistoryTalentApi = async (id, token) =>
    await axiosInstance
        .get(`/api/company/history/${id}`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        .then(response => response)
        .catch(error => error.response);


export const getJobOffersList = async (token) => 
    await axiosInstance.get(`/api/company/job-offers-list`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        }
    })
    .then(response => response)
    .catch(error => error.response);

export const fetchTalentsByJobOfferApi = async (job_offer_application_id, page, query, token) => {
    const params = {};
    if (page) params.page = page;
    if (query) params.filter_type = query;

    const response = await axiosInstance.get(`/api/job_offer/${job_offer_application_id}`, {
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json"
        },
        params: params
    });

    return response.data;
};

export const getJobOffersRequest = async (token, query) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: query
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            "Content-Type": "application/json"
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })


export const getJobOffersRequestApi = async (token, query) =>
    await axiosInstance.get(`/api/company/job_offers`,{
        headers: {
            Authorization: 'Bearer ' + token,
            "Content-Type": "application/json"
        } , 
        params : query
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const deleteJobOfferRequest = async (token, id) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `mutation {
          deleteJobOffer(id: ${id}) {
            id
          }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const deleteJobOfferRequestApi = async (token, id) =>
    await axiosInstance.post(`/api/company/job_offer/delete/${id}`,{}, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const updateApplicationTimeRequest = async (token, id, status) =>
    await axiosInstance.put('/api/job_offer/update_application_time', {
        id: id,
        status: status
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const addApplicationTimeRequest = async (token, data) => {
    return await axiosInstance.post('/api/job_offer/add_application_time', data, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

export const updateJobOfferRequest = async (token, id,data) => {
    return await axiosInstance.put('/api/job_offer/update_template/'+id, data, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

export const jobOfferFicheRequest = async (token, data) => {
    return await axiosInstance.post('/api/job_offer/fiche', data, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

export const jobOfferProposalRequest = async (token) => {
    return await axiosInstance.get('/api/job_offer/proposals', {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

export const jobOfferContractRequest = async (token, id) => {
    return await axiosInstance.get('/api/job_offer/contract_template/' + id, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

export const signJobOfferContractRequest = async (token, data) => {
    return await axiosInstance.post('/api/job_offer/contract_sign', data,{
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

export const updateContractRequest = async (token, data) => {
    return await axiosInstance.post('/api/job_offer/contract_update', data,{
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

export const endContractRequest = async (token, data) => {
    return await axiosInstance.post('/api/job_offer/contract_end', data,{
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}
