// Import the redux-saga/effects
import {call, put, takeEvery} from 'redux-saga/effects'

// Import all action and api's
import {
    DELETE_JOB_OFFER,
    GET_JOB_OFFER,
    GET_JOB_OFFERS, 
    GET_JOB_OFFERS_API, 
    JOB_OFFER_FICHE,
    UPDATE_APPLICATION_TIME,
    UPSERT_JOB_OFFER,
    GET_JOB_OFFERS_COUNT,
    JOB_OFFER_PROPOSAL,
    JOB_OFFER_CONTRACT,
    JOB_OFFER_CONTRACT_SIGN,
    JOB_OFFER_CONTRACT_PROPOSAL, JOB_OFFER_CONTRACT_END,
    DELETE_JOB_OFFER_API,
    GET_TALENTS_BY_JOB_OFFER_REQUEST,
    GET_JOB_OFFERS_LIST_REQUEST,
    GET_HISTORY_TALENT_REQUEST,
} from '../action-types'

// Import all api's
import {
    deleteJobOfferRequest,
    getJobOffersRequest,
    jobOfferFicheRequest, jobOfferProposalRequest, jobOfferContractRequest,
    updateApplicationTimeRequest, signJobOfferContractRequest, updateContractRequest, endContractRequest,
    getJobOffersRequestApi,
    deleteJobOfferRequestApi,
    fetchTalentsByJobOfferApi,
    getJobOffersList,
    getHistoryTalentApi
} from '../api/JobOffer'

import {
    getJobOfferProposalSuccess,
    getJobOfferContractSuccess,
    getJobOffersCountSuccess,
    getJobOffersSuccess,
    getJobOfferSuccess, jobOfferFicheSuccess,
    updateApplicationTimeSuccess,
    upsertJobOfferSuccess, signJobOfferContractSuccess, updateContractSuccess, endContractSuccess,
    getTalentsByJobOfferSuccess,
    getTalentsByJobOfferFailure,
    getJobOffersListFailure,
    getJobOffersListSuccess,
    getHistoryTalentSuccess,
    getHistoryTalentFailure
} from '../actions/JobOffer'

import {fetchError, fetchExpired, fetchStart, fetchSuccess} from '../actions/Common'
import { toast } from "react-toastify";

function* fetchHistoryTalent(action) {
    try {
        const { id, token } = action.payload;
        const response = yield call(getHistoryTalentApi, id, token);

        if (response.status === 200) {
            yield put(getHistoryTalentSuccess(response.data));
        } else {
            yield put(getHistoryTalentFailure(response.data.message));
        }
    } catch (error) {
        yield put(getHistoryTalentFailure("Erreur réseau"));
    }
}


function* fetchJobOffersList(action) {
    try {
        const { token } = action.payload;
        const response = yield call(getJobOffersList, token);

        if (response.status === 200) {
            yield put(getJobOffersListSuccess(response.data.job_offers));
        } else {
            yield put(getJobOffersListFailure(response.data.message));
        }
    } catch (error) {
        yield put(getJobOffersListFailure("Erreur réseau"));
    }
}


function* fetchTalentsByJobOfferSaga(action) {
    try {
        yield put(fetchStart())
        const { job_offer_application_id, page, query,token } = action.payload;
        const response = yield call(fetchTalentsByJobOfferApi, job_offer_application_id, page, query,token);

        
        yield put(getTalentsByJobOfferSuccess(
            response.data.job_offers_application.data,
            response.data.job_offers_application.paginatorInfo,
            response.count_status,
            response.data.job_offers_application.data
        ));
        yield put(fetchSuccess())
    } catch (error) {
        yield put(getTalentsByJobOfferFailure(error.message));
    }
}

function* getJobOffers({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(getJobOffersRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getJobOffersSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getJobOffersApi({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(getJobOffersRequestApi, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getJobOffersSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getJobOffersCount({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(getJobOffersRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getJobOffersCountSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* jobOfferFiche({token, query, isToast, intl, isToastJobOfferGenerate,}) {
    try {
        yield put(fetchStart())
        const response = yield call(jobOfferFicheRequest, token, query)
        if (response.status === 200) {
            yield put(jobOfferFicheSuccess(response.data))
            yield put(fetchSuccess())
            isToast === true && toast.success(intl.formatMessage({ id: "successJoboffer" }));
            isToastJobOfferGenerate === true && toast.success(intl.formatMessage({ id: "generate_jobOffer_success" }));
        }
        if (response.status === 401) {
            yield put(fetchExpired())
            toast.error(intl.formatMessage({ id: "error401" }));

        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
            toast.error(intl.formatMessage({ id: "server_error_portage" }));
        }
    } catch (error) {
        console.log(error)
        toast.error(intl.formatMessage({ id: "global_error_portage" }));
    }
}

function* getJobOffer({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(getJobOffersRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getJobOfferSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* deleteJobOffer({token, id, query, intl}) {
    try {
        yield put(fetchStart())
        const response = yield call(deleteJobOfferRequest, token, id)
        if (response.status === 200) {
            const newResponse = yield call(getJobOffersRequest, token, query)
            if (newResponse.status === 200) {
                if (newResponse.data.errors && newResponse.data.errors.length > 0) {
                    yield put(fetchError(newResponse.data))
                } else {
                    yield put(getJobOffersSuccess(newResponse.data))
                    yield put(fetchSuccess())
                    toast.success("The job offer has been deleted.");
                }
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
            toast.error(intl.formatMessage({ id: "error401" }));
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
            toast.error(intl.formatMessage({ id: "server_error_portage" }));
        }
    } catch (error) {
        console.log(error)
        toast.error(intl.formatMessage({ id: "global_error_portage" }));
    }
}

function* deleteJobOfferApi({token, id, query, intl}) {
    try {
        yield put(fetchStart())
        const response = yield call(deleteJobOfferRequestApi, token, id)
        if (response.status === 200) {
            const newResponse = yield call(getJobOffersRequestApi, token, query)
            if (newResponse.status === 200) {
                if (newResponse.data.errors && newResponse.data.errors.length > 0) {
                    yield put(fetchError(newResponse.data))
                } else {
                    yield put(getJobOffersSuccess(newResponse.data))
                    yield put(fetchSuccess())
                    toast.success("The job offer has been deleted.");
                }
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
            toast.error(intl.formatMessage({ id: "error401" }));
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
            toast.error(intl.formatMessage({ id: "server_error_portage" }));
        }
    } catch (error) {
        console.log(error)
        toast.error(intl.formatMessage({ id: "global_error_portage" }));
    }
}

function* upsertJobOffer({token, query,intl}) {
    try {
        yield put(fetchStart())
        const response = yield call(getJobOffersRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(upsertJobOfferSuccess(response.data))
                yield put(fetchSuccess())
                toast.success(intl.formatMessage({ id: "jobOfferUpdated" }))
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
            toast.error(intl.formatMessage({ id: "error401" }));
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
            toast.error(intl.formatMessage({ id: "server_error_portage" }));
        }
    } catch (error) {
        console.log(error)
        toast.error(intl.formatMessage({ id: "global_error_portage" }));
    }
}

function* updateApplicationTime({token, id, status, intl}) {
    try {
        yield put(fetchStart())
        const response = yield call(updateApplicationTimeRequest, token, id, status)
        if (response.status === 200) {
            yield put(updateApplicationTimeSuccess(response.data))
            yield put(fetchSuccess())
           status === "accepted" && toast.success(intl.formatMessage({ id: "acceptShedule" }))
           status === "rejected" && toast.success(intl.formatMessage({ id: "rejectShedule" }))
        }
        if (response.status === 401) {
            yield put(fetchExpired())
            toast.error(intl.formatMessage({ id: "error401" }));
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
            toast.error(intl.formatMessage({ id: "server_error_portage" }));
        }
    } catch (error) {
        console.log(error)
        toast.error(intl.formatMessage({ id: "global_error_portage" }));
    }
}

function* jobOfferProposal({token,intl}) {
    try {
        //yield put(fetchStart())
        const response = yield call(jobOfferProposalRequest, token)
        if (response.status === 200) {
            yield put(getJobOfferProposalSuccess(response.data))
            //yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* jobOfferContract({token, id}) {
    try {
        yield put(fetchStart())
        const response = yield call(jobOfferContractRequest, token, id)
        if (response.status === 200) {
            yield put(getJobOfferContractSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* signJobOfferContract({token, data}) {
    try {
        yield put(fetchStart())
        const response = yield call(signJobOfferContractRequest, token, data)
        if (response.status === 200) {
            yield put(signJobOfferContractSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* updateContract({token, data}) {
    try {
        yield put(fetchStart())
        const response = yield call(updateContractRequest, token, data)
        if (response.status === 200) {
            yield put(updateContractSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* endContract({token, data}) {
    try {
        yield put(fetchStart())
        const response = yield call(endContractRequest, token, data)
        if (response.status === 200) {
            yield put(endContractSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

// Export the saga (todo-saga)
export default function* todoSaga() {
    yield takeEvery(GET_JOB_OFFERS, getJobOffers)
    yield takeEvery(GET_JOB_OFFERS_API, getJobOffersApi)
    yield takeEvery(GET_JOB_OFFER, getJobOffer)
    yield takeEvery(DELETE_JOB_OFFER, deleteJobOffer)
    yield takeEvery(DELETE_JOB_OFFER_API, deleteJobOfferApi)
    yield takeEvery(UPSERT_JOB_OFFER, upsertJobOffer)
    yield takeEvery(UPDATE_APPLICATION_TIME, updateApplicationTime)
    yield takeEvery(JOB_OFFER_FICHE, jobOfferFiche)
    yield takeEvery(GET_JOB_OFFERS_COUNT, getJobOffersCount)
    yield takeEvery(JOB_OFFER_PROPOSAL, jobOfferProposal)
    yield takeEvery(JOB_OFFER_CONTRACT, jobOfferContract)
    yield takeEvery(JOB_OFFER_CONTRACT_SIGN, signJobOfferContract)
    yield takeEvery(JOB_OFFER_CONTRACT_PROPOSAL, updateContract)
    yield takeEvery(JOB_OFFER_CONTRACT_END, endContract)
    yield takeEvery(GET_TALENTS_BY_JOB_OFFER_REQUEST,fetchTalentsByJobOfferSaga)
    yield takeEvery(GET_JOB_OFFERS_LIST_REQUEST,fetchJobOffersList)
    yield takeEvery(GET_HISTORY_TALENT_REQUEST, fetchHistoryTalent)
}