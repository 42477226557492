import {axiosInstance, axiosInstanceGraphQL} from '../../axios/axios'

export const getSearchRequest = async (token) =>
    await axiosInstance.get('/api/search', {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const saveSearchRequest = async (token, form) =>
    await axiosInstance.post('/api/search/store', form, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const deleteSearchRequest = async (token, id) =>
    await axiosInstance.post('/api/search/delete/' + id, {}, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const searchActionRequest = async (token, form) =>
    await axiosInstanceGraphQL.post('/api/search/action', form, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const searchMatchRequest = async (token, id) =>
    await axiosInstanceGraphQL.get('/api/search/match/' + id,  {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getSavedFilter = async (token) =>
    await axiosInstance.get('/api/company/search-filters',  {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
            })
export const saveFilter = async (filterData, token) => 
    await axiosInstance.post(`/api/company/search-filters`, filterData, {
        headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
        },
    })
    .then(response => response)
    .catch(error => error.response);

export const getSavedFilterById = async (searchFilterId, token) =>
    await axiosInstance.get(`/api/company/search-filters/${searchFilterId}`, {
        headers: {
        Authorization: 'Bearer ' + token,
        },
    })
        .then((response) => response)
        .catch((error) => error.response);

export const deleteFilter = async (searchFilterId, token) =>
    await axiosInstance.delete(`/api/company/search-filters/${searchFilterId}`, {
        headers: {
        Authorization: 'Bearer ' + token,
        },
    })
        .then((response) => response)
        .catch((error) => error.response);


export const updateFilter = async (searchFilterId, updatedData, token) => {
  try {
    const response = await axiosInstance.put(
      `/api/company/search-filters/${searchFilterId}`,
      updatedData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to update filter");
  }
};
