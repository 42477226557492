import {
    CLOSE_FILTER_JOB,
    DELETE_SEARCH,
    DELETE_SEARCH_SUCCESS,
    GET_SEARCH,
    GET_SEARCH_SUCCESS, OPEN_FILTER_JOB,
    SAVE_SEARCH,
    SAVE_SEARCH_SUCCESS,
    SEARCH_ACTION,
    SEARCH_ACTION_SUCCESS,
    UPLOAD_CV_WARNING,
    TALENT_ALREADY_APPLIED,
    SET_SEARCH_STATE,
    SEARCH_MATCH_SUCCESS, SEARCH_MATCH,
    GET_SAVED_FILTERS_REQUEST,
    GET_SAVED_FILTERS_SUCCESS,
    GET_SAVED_FILTERS_FAILURE,
    GET_SAVED_FILTER_BY_ID_REQUEST,
    GET_SAVED_FILTER_BY_ID_SUCCESS,
    GET_SAVED_FILTER_BY_ID_FAILURE,
    DELETE_FILTER_REQUEST,
    DELETE_FILTER_SUCCESS,
    DELETE_FILTER_FAILURE,
    SAVE_FILTER_REQUEST,
    SAVE_FILTER_SUCCESS,
    SAVE_FILTER_FAILURE,
    UPDATE_FILTER_REQUEST,
    UPDATE_FILTER_SUCCESS,
    UPDATE_FILTER_FAILURE
} from '../action-types'

export const updateFilterRequest = (searchFilterId, updatedData, token, intl) => ({
    type: UPDATE_FILTER_REQUEST,
    payload: { searchFilterId, updatedData, token, intl},
  });
  
  export const updateFilterSuccess = (updatedFilter) => ({
    type: UPDATE_FILTER_SUCCESS,
    payload: updatedFilter,
  });
  
  export const updateFilterFailure = (error) => ({
    type: UPDATE_FILTER_FAILURE,
    payload: error,
  });

export const saveFilterRequest = (filterData, token, intl) => ({
    type: SAVE_FILTER_REQUEST,
    payload: { filterData, token, intl },
  });
  
  export const saveFilterSuccess = (filter) => ({
    type: SAVE_FILTER_SUCCESS,
    payload: filter,
  });
  
  export const saveFilterFailure = (error) => ({
    type: SAVE_FILTER_FAILURE,
    payload: error,
  });

export const deleteFilterRequest = (searchFilterId, token, intl) => ({
    type: DELETE_FILTER_REQUEST,
    payload: { searchFilterId, token, intl },
  });
  
  export const deleteFilterSuccess = (deletedFilter) => ({
    type: DELETE_FILTER_SUCCESS,
    payload: deletedFilter,
  });
  
  export const deleteFilterFailure = (error) => ({
    type: DELETE_FILTER_FAILURE,
    payload: error,
  });

export const getSavedFilterByIdRequest = (searchFilterId, token) => ({
    type: GET_SAVED_FILTER_BY_ID_REQUEST,
    payload: { searchFilterId, token },
  });
  
  export const getSavedFilterByIdSuccess = (savedFilter) => ({
    type: GET_SAVED_FILTER_BY_ID_SUCCESS,
    payload: savedFilter,
  });
  
  export const getSavedFilterByIdFailure = (error) => ({
    type: GET_SAVED_FILTER_BY_ID_FAILURE,
    payload: error,
  });

export const getSavedFiltersRequest = (token) => ({
    type: GET_SAVED_FILTERS_REQUEST,
    payload: { token },
  });
  
  export const getSavedFiltersSuccess = (filters) => ({
    type: GET_SAVED_FILTERS_SUCCESS,
    payload: filters,
  });
  
  export const getSavedFiltersFailure = (error) => ({
    type: GET_SAVED_FILTERS_FAILURE,
    payload: error,
  });

export const saveSearch = (token, form) => {
    return {
        type: SAVE_SEARCH,
        token: token,
        form: form
    }
}

export const saveSearchSuccess = (data) => {
    return {
        type: SAVE_SEARCH_SUCCESS,
        data: data,
    }
}

export const getSearch = (token) => {
    return {
        type: GET_SEARCH,
        token: token
    }
}

export const getSearchSuccess = (data) => {
    return {
        type: GET_SEARCH_SUCCESS,
        data: data,
    }
}

export const deleteSearch = (token, id) => {
    return {
        type: DELETE_SEARCH,
        token: token,
        id: id
    }
}

export const deleteSearchSuccess = (data) => {
    return {
        type: DELETE_SEARCH_SUCCESS,
        data: data,
    }
}

export const searchAction = (token, form) => {
    return {
        type: SEARCH_ACTION,
        token: token,
        form: form
    }
}

export const searchActionSuccess = (data) => {
    return {
        type: SEARCH_ACTION_SUCCESS,
        data: data,
    }
}

export const toggleShowCVWarning = () => {
    return {
        type: UPLOAD_CV_WARNING
    }
}

export const talentALreadyApplied = (data) => {
    return {
        type: TALENT_ALREADY_APPLIED,
        data: data,
    }
}

export const openFilterJob = () => {
    return {
        type: OPEN_FILTER_JOB,
    }
}

export const closeFilterJob = () => {
    return {
        type: CLOSE_FILTER_JOB,
    }
}

export const setTalentState = (state) => {
    return {
        type: SET_SEARCH_STATE,
        payload: state,
    }
}

export const searchMatch = (token, id) => {
    return {
        type: SEARCH_MATCH,
        token: token,
        id: id
    }
}

export const searchMatchSuccess = (data) => {
    return {
        type: SEARCH_MATCH_SUCCESS,
        data: data,
    }
}
